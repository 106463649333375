#region-footer2-second {

  .region-footer2-second-inner:before {
    height: 100%;
    display: inline-block;
    vertical-align: top;
    content: '';
  }

  div.block {
    float: left;
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }

  div.block-webmeal-appstore {
    div.button {
      margin-right: 1em;
    }
  }
}

@media (max-width: 739px) {
  #section-footer {
    div.block {
      margin-bottom: 2em;
    }
  }

  #region-footer2-second {
    div.block {
      display: block;
      width: 100%;
    }
  }

  div.block-webmeal-appstore {
    div.button {
      display: inline-block;
    }
  }
}

@media (min-width: 740px) {
  div.block-webmeal-appstore {
    div.button {
      margin-bottom: 1em;
      display: inline-block;
    }
  }
}
