/* ----- MAX TABLET ----- */
@media (max-width: 979px) {

  /* branding > branding-blocks */
  .region-branding .branding-blocks {
    /*width: 65%;*/
  }

  /* branding > branding-blocks > block-menu */
  .region-branding .branding-blocks .block-menu {
    width: 60%;
    float: left;
  }

  .region-branding .branding-blocks .block-menu .menu {
    padding-top: 2em;
  }

  .region-branding .branding-blocks .block-menu .menu .leaf {
    margin-bottom: 2em;
  }

  .zone-branding-wrapper .zone-branding .block-menu {
    display: none;
  }

  .not-logged-in .branding-blocks .block-shopping-cart-block {
    margin-left: 60%;
    width: 40%;
  }

  .page .section-content {
    margin-top: 115px;
  }

  .region-branding .region-branding-inner {
    height: 80px;
  }

  #section-header #zone-branding-wrapper .zone-branding .region-branding .region-branding-inner .logo-img {
    width: 33%;
  }

  .zone-branding .branding-blocks {
    width: 67%;
  }

  .not-logged-in .branding-blocks .block-shopping-cart-block {
    margin-left: 55%;
    width: 45%;
  }

  .logged-in .block-shipping-selector {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    bottom: initial;
    height: 42px;
    top: initial;
    width: 45%;
    margin-left: 5%;
  }

  .logged-in .block-shopping-cart-block {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    bottom: initial;
    width: 45%;
    margin-left: 5%;
  }

}

/* ----- MAX NAGY MOBIL ----- */
@media (max-width: 739px) {

  /* zone-user */
  #zone-menu-wrapper {
    display: none;
  }

  #zone-user-wrapper {
    z-index: 200;
    position: fixed;
    height: 34px;
    top: 0;
  }

  #not-slider-menu span {
    display: none;
  }

  //.zone-branding-wrapper .zone-branding .region-branding {
    //margin-top: 34px;
  //}

}

@media (max-width: 620px) {
  body:not(.front) .section-header #zone-branding-wrapper .zone-branding .region-branding .region-branding-inner .logo-img {
    display: none;
  }

  .logged-in .branding-blocks {
    width: 100%;
    font-size: 0;
  }

  .logged-in .block-shipping-selector,
  .logged-in .block-shopping-cart-block {
    width: 50%;
    margin: 0;
    display: inline-block;
    right: 0;
  }
}

/* ----- MAX KIS MOBIL ----- */
@media (max-width: 499px) {
  #region-user-first {
    margin: 0;
  }

  #region-user-second #slider-menu {
    background-position: center right;
    margin-right: 10px;
  }

  #slider-menu span {
    display: none;
  }

  .region-branding .branding-blocks {
    position: relative;
    width: 100%;
    float: none;
    height: 90px;
  }

  .not-logged-in .region-branding .branding-blocks {
    height: auto;
  }

  .block-shipping-selector,
  .block-shopping-cart-block {
    /*right: 0;*/
    /*left: 0;*/
    margin-left: auto;
    margin-right: auto;
  }

  .branding-blocks .block-shipping-selector {
    /*top: 7px;*/
  }

  .logged-in .branding-blocks .block-shopping-cart-block {
    /*top: 55px;*/
  }

  .not-logged-in .branding-blocks {
    text-align: center;
  }

  .not-logged-in .branding-blocks .block-shopping-cart-block {
    margin: 10px 0;
  }

}
