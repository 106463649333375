form#user-register-form {
  input#edit-submit {
    font-size: 14px;
    padding: 15px 20px;
    width: 90%;
    margin-top: 10px;
  }

  div#edit-actions {
    width: 200px;
  }
}

form#user-register-form #edit-osszeg option {
  text-align: right;
}

form#search-block-form {
  background-color: white;
  height: 36px;
  margin-top: 10px;
  border-radius: 3px;
  float: right;

  div.container-inline {
    position: relative;
  }

  div.form-actions {
    position: absolute;
    right: 4px;
    top: 1px;
  }

  .form-text {
    border: 0 none;
    width: 100%;
    height: 36px;
    background-color: white;
    padding: 5px 28px 5px 5px;
    position: relative;
  }

  .form-submit {
    border-radius: 0;
    border: 0 none;
    background: transparent url("../images/picto_magnifying_glass.png") no-repeat;
    text-indent: -9999px;
    position: relative;
    margin: 9px 0 0;
  }
}

.egyenleginfo-rendelesinev {
  float: left;
  margin-right: 0.5em;
}

.form-item-shipping {
  margin-top: 1px;
}

.view-etlap input.form-button-disabled {
  /*background: -moz-linear-gradient(center top , #ddd 0%, #fff 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);*/
  background: -moz-linear-gradient(#ddd 0%, #fff 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  color: #e55;
}

.view-etlap input {
  color: #51a625;
}

.page-etlap .view-etlap li.views-row {
  display: inline;
  float: left;
  min-height: 40em;
  margin-left: 8%;
  padding: 0.2em 0.2em 30px;
  width: 90%;
}

.view-etlap li div.views-field-field-date-1 {
  padding: 0.6em;
  border-radius: 0.5em 0.5em 0 0;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.views-field-tooltip-row {
  div.field-name-commerce-price,
  div.views-field-nothing-1,
  div.views-field-jr-custom-product-outdated-field {
    clear: both;
    font-weight: bold;
    right: 0.1em;
    position: absolute;
    bottom: 1em;
  }
  div.views-field-title {
    float: left;
    width: 88%;
  }
  div.views-field-nothing-2 {
    float: right;
    right: 3.5em;
    position: absolute;
    bottom: 0.7em;
  }
  div.field-name-field-kedvezmenyes-ar {
    float: right;
    right: 0.1em;
    position: absolute;
    bottom: 0.5em;
  }
}

/*
 * Hétfő
 */
.view-etlap li.views-row-1 div.views-field-field-date-1,
.view-etlap li.views-row-6 div.views-field-field-date-1,
.view-etlap li.views-row-11 div.views-field-field-date-1 {
  background-color: #51a625;
}

/*
 * Kedd
 */
.view-etlap li.views-row-2 div.views-field-field-date-1,
.view-etlap li.views-row-7 div.views-field-field-date-1,
.view-etlap li.views-row-12 div.views-field-field-date-1 {
  background-color: #f38e00;
}

/*
 * Szerda
 */
.view-etlap li.views-row-3 div.views-field-field-date-1,
.view-etlap li.views-row-8 div.views-field-field-date-1,
.view-etlap li.views-row-13 div.views-field-field-date-1 {
  background-color: #00a6ec;
}

/*
 * Csütörtök
 */
.view-etlap li.views-row-4 div.views-field-field-date-1,
.view-etlap li.views-row-9 div.views-field-field-date-1,
.view-etlap li.views-row-14 div.views-field-field-date-1 {
  background-color: #e60144;
}

/*
 * Péntek
 */
.view-etlap li.views-row-5 div.views-field-field-date-1,
.view-etlap li.views-row-10 div.views-field-field-date-1,
.view-etlap li.views-row-15 div.views-field-field-date-1 {
  background-color: #855aa2;
}

.view-napi-menu {
  color: #633205;
  padding: 0.5em;
  border-radius: 0 0 0.5em 0.5em;
}

.view-napi-menu.view-display-id-block {
  background-color: #e4ddcd;
}

.view-napi-menu.view-display-id-block_2 {
  background-color: #c2bbab;
}

.view-napi-menu div.views-row {
  border-bottom: 1px solid #c9b89a;
  padding: 0.5em 0;
  position: relative;
  min-height: 8em;
}

.view-napi-menu div.views-row-last {
  border-bottom: none;
}

.view-napi-menu div.views-field-add-to-cart-form {
  position: absolute;
  bottom: 0.5em;
  /*  width: 100%; */
}

.day-past .view-napi-menu div.views-field-add-to-cart-form {
  display: none;
}

.view-napi-menu div.foodtype {
  position: absolute;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform-origin: right top;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  text-align: center;
  padding-bottom: 0.2em;
  color: white;
  background: #633205;
  border-radius: 0.5em 0.5em 0 0;
  width: 8em;
  left: -10.1em;
}

.zone-branding .region-branding {
  margin-top: 0;
}

.zone-branding .region-branding .logo-img {
  float: left;
  width: 25%;
  background-color: white;
  margin: 0;
  padding: 0;
  border-radius: 0 0 2em 0;
  vertical-align: middle;
}

.zone-branding .region-branding #piroselem {
  float: left;
  background-color: #e60144;
  width: 5em;
  height: 2em;
  border-radius: 0 0 1em 0;
}

.zone-branding .region-branding #zoldelem {
  float: left;
  background-color: #51a625;
  width: 3em;
  height: 1em;
  border-radius: 0 0 0.5em 0;
}

.view-etlap .field-name-field-holiday {
  min-height: 37em;
  border-radius: 0 0 0.5em 0.5em;
  border: 1px solid #633205;
  /*  background-color: #e4ddcd; */
  background-image: radial-gradient(#e4ddcd, #e4ddcd, #e4ddcd, white)
}

.view-commerce-cart-form table,
.view-konyhai-komissio table,
.view-iskolai-szetosztasi-lista table {
  width: 80%;
  margin-bottom: 3em;
  border-bottom: 2px solid;
}

.view-grouping-header {
  font-size: 1.5em;
  font-weight: bold;
}

.view-commerce-cart-form caption,
.view-konyhai-komissio caption,
.view-iskolai-szetosztasi-lista caption,
.view-commerce-cart-summary caption {
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
}

.view-konyhai-komissio td.views-field-quantity {
  width: 20%;
}

.view-konyhai-komissio td.views-field-field-cikkszam {
  width: 10%;
}

.front .node {
  border: none;
}

#views-form-commerce-cart-form-default div.view-grouping-header,
#commerce-checkout-form-review div.view-grouping-header {
  padding-bottom: 1em;
}

#views-form-commerce-cart-form-default table td,
#commerce-checkout-form-review .view-commerce-cart-summary table td {
  padding: 0.4em 10px;
}

#views-form-commerce-cart-form-default div.field-name-field-tanulo-neve,
#commerce-checkout-form-review div.field-name-field-tanulo-neve {
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.4em 10px;
}

#views-form-commerce-cart-form-default div.field-name-field-iskola,
#views-form-commerce-cart-form-default div.field-name-field-osztaly,
#views-form-commerce-cart-form-default div.field-name-field-nickname,
#commerce-checkout-form-review div.field-name-field-iskola,
#commerce-checkout-form-review div.field-name-field-osztaly,
#commerce-checkout-form-review div.field-name-field-nickname {
  padding: 0 10px;
}

#views-form-commerce-cart-form-default div.field-name-field-nickname div.field-label {
  font-weight: normal;
}

#views-form-commerce-cart-form-default table.views-table,
#commerce-checkout-form-review table.views-table {
  margin-bottom: 1.5em;
  border-bottom: 2px solid;
}

#views-form-commerce-cart-form-default table.views-table tbody tr.odd,
#commerce-checkout-form-review table.views-table tbody tr.odd {
  background-color: #e4ddcd;
}

#juniormenu-etlap-info,
h1#page-title {
  text-align: center;
}

.line-item-total {
  padding-bottom: 15px;
}

.links .line-item-summary-checkout {
  background: #2698F2 none 0 0 no-repeat;
  border: 0;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -khtml-border-radius: 8px;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: normal;
  padding: 5px 10px 5px 10px;
}

.links .line-item-summary-checkout:focus,
.links .line-item-summary-checkout:active {
  -webkit-box-shadow: #06f 0 0 7px;
  box-shadow: #06f 0 0 7px;
  z-index: 1;
}

.links .line-item-summary-checkout a {
  color: #ffffff;
}

.line-item-summary .links li.last {
  padding-right: 10px;
}

.day-normal {
  background-color: #e4ddcd;
}

.day-past {
  background-color: #c2bbab;
}

.view-etlap form.megrendelve input.form-button-disabled,
.outdated-and-ordered {
  color: blue;
}

body.toolbar #toolbar-home a span {
  background: transparent url("../images/junior_logo_RGB_BLACK_106px.png") no-repeat 0 0;
}

.form-item-commerce-payment-payment-method {
  height: 70px;
}

/*
.page-checkout #commerce-checkout-form-checkout fieldset label {
  width: 20% !important;
}
*/

.payment-method-logo {
  padding-bottom: 5px;
  padding-top: 6px;
}

.form-item-commerce-payment-payment-method input {
  margin-top: 32px;
}

#node-page-349 .dnd-atom-wrapper {
  display: block;
}

.menu-toggle {
  top: -80px;
}

/* ----- ----- ----- *\
    WEBMEAL DESIGN
\* ----- ----- ----- */

/* ----- user-menu -----*/

#zone-user #region-user-first {
  height: 100%;
  display: inline-block;
}

#zone-user #region-user-second {
  height: 100%;
  display: inline-block;
  padding-right: 10px;
}

#zone-user #region-user-second .region-inner {
  padding: 0 10px;
}

.section-header {
  .zone-user-wrapper,
  .block-shopping-cart-block .content .view-shopping-cart .line-item-summary ul.links,
  #zone-user #region-user-second {
    background-color: #bfbfbf;
  }
}

.context-iskola {
  .section-header {
    .zone-user-wrapper,
    .block-shopping-cart-block .content .view-shopping-cart .line-item-summary ul.links,
    #zone-user #region-user-second {
      background-color: #fdaf17;
    }
  }
}

.context-iroda {
  .section-header {
    .zone-user-wrapper,
    .block-shopping-cart-block .content .view-shopping-cart .line-item-summary ul.links,
    #zone-user #region-user-second {
      background-color: #5a5aff;
    }
  }
}

.block-menu-menu-user-menu ul li {
  list-style: none none;
  display: inline-block;
  margin-top: 3px;
}

/* zone-user > slider-menu */

#slider-menu {
  width: 100%;
  padding: 2px 40px;
  background: url("../images/bar-icon-100x100.png") no-repeat right;
  background-size: 22px;
  height: 22px;
  cursor: pointer;
  float: right;
  text-align: right;
  margin-top: 4px;
}

.not-logged-in #slider-menu {
  width: auto;
}

#not-slider-menu {
  text-align: center;
  width: 100%;
}

#not-slider-menu span {
  position: absolute;
  top: 6px;
  left: 0;
  right: 40px;
}

#slider-menu span,
#not-slider-menu span a {
  font-weight: 700;
  color: white;
  font-size: 12px;
  line-height: 1.8;
  text-transform: uppercase;
}

/* ----- search ----- */

.region-user-first-inner .search-form .form-submit,
.region-user-first-inner .search-form label {
  display: none;
}

.region-user-first-inner .search-form .form-text {
  margin-top: 5px;
  width: 100%;
  -webkit-box-shadow: inset 2px 2px 2px #ddd;
  -moz-box-shadow: inset 2px 2px 2px #ddd;
  box-shadow: inset 2px 2px 2px #ddd;

  text-indent: 20px;
  background: #fffada url('../images/search-icon-50x50.png') no-repeat 5px 5px;
  background-size: 14px;

  padding-top: 3px;
}

/* ----- branding ----- */

.zone-branding-wrapper {
  background-color: #ededed;
}

.context-iskola .zone-branding-wrapper {
  background-color: #fffada;
}

.context-iroda .zone-branding-wrapper {
  background-color: #e1e1ff;
}

.zone-branding-wrapper {
  box-shadow: 0 1px 10px #888888;
  position: fixed;
  width: 100%;
  z-index: 10;
}

body.toolbar-drawer {
  .zone-branding-wrapper {
    top: 0;
  }
}

.zone-branding-wrapper div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.zone-branding-wrapper .zone-branding .region-branding {
  margin-bottom: 0;
  width: 100%;
  padding: 0 10px;
}

.zone-branding .region-branding .region-branding-inner {
  position: relative;
  padding: 0 10px;
}

/* branding > logo-img */

#zone-branding-wrapper .zone-branding .region-branding .region-branding-inner .logo-img {
  background-color: transparent;
  width: 28%;
  float: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

/* branding > branding-blocks */
.branding-blocks {
  display: inline-block;
  width: 72%;
}

.region-branding-inner {
  height: 120px;
  font-size: 0;
}

.region-branding-inner::before {
  content: '';
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.region-branding-inner .block-menu {
  display: inline-block;
  vertical-align: middle;
  width: 75%;
}

.region-branding-inner .block-menu .menu {
  text-align: center;
}

.region-branding-inner .block-menu .menu .leaf {
  padding: 0;
  margin: 0;
  width: auto;
  display: inline-block;
}

.region-branding-inner .block-menu .menu .leaf span,
.region-branding-inner .block-menu .menu .leaf a {
  padding: 10px;
  margin: 0 10px 0 10px;
  text-transform: uppercase;
  color: #aaa;
  font-size: medium;
}

.region-branding-inner .block-menu .menu .leaf a.active {
  color: #fff;
}

.context-iskola {
  .region-branding-inner {
    .block-menu {
      .menu {
        .leaf {
          a.active {
            background-color: #fdaf17;
          }
        }
      }
    }
  }
}

.context-iroda {
  .region-branding-inner {
    .block-menu {
      .menu {
        .leaf {
          a.active {
            background-color: #5a5aff;
          }
        }
      }
    }
  }
}

.region-branding-inner .block-menu .menu .leaf.not-active a.active {
  background-color: transparent;
  color: #aaa;
}

.region-branding-inner .block-menu .menu li.leaf:last-of-type .not-active a.active {
  background-color: transparent;
  color: #aaa;
}

/* branding > branding-blocks > block-shipping-selector */

.block-shipping-selector {
  position: absolute;
  right: 10px;
  width: 175px;
  border: solid 1px #aaa;
  padding: 5px;
  top: 15px;
  text-align: center;
}

.block-shipping-selector label {
  text-transform: uppercase;
  font-size: 10px;
  color: #aaa;
}

.block-shipping-selector span a {
  font-size: 15px;
  color: #000;
  font-weight: 700;
}

/* branding > branding-blocks > shopping-cart-block */

.block-shopping-cart-block {
  position: absolute;
  right: 10px;
  width: 175px;
  height: auto;
  bottom: 15px;
  font-size: 12px;
}

.not-logged-in .block-shopping-cart-block {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  bottom: initial;
  width: 25%;
}

.region-branding .block-shopping-cart-block .block-inner {
  height: auto;
  position: relative;
}

.region-branding .block-shopping-cart-block .block-inner .line-item-summary a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.region-branding .block-shopping-cart-block .content {
  border: solid 1px #aaa;
  background: transparent url("../images/cart.png") no-repeat 1% 48%;
  background-position-x: 5%;
  padding-top: 3px;
  padding-bottom: 3px;
  text-transform: uppercase;
  height: 42px;
  width: 100%;
}

.region-branding .block-shopping-cart-block .content p {
  margin: 0;
}

.block-shopping-cart-block .view-id-shopping_cart {
  cursor: default;

}

.zone-branding .block-shopping-cart-block .content .view-shopping-cart .line-item-summary ul {
  display: none;
}

.zone-branding .block-shopping-cart-block .content .view-shopping-cart .line-item-summary > div,
.zone-branding .block-shopping-cart-block .content .view-shopping-cart .line-item-quantity,
.zone-branding .block-shopping-cart-block .content .view-shopping-cart .line-item-total {
  line-height: 3.5;
}

.zone-branding .block-shopping-cart-block .content .view-shopping-cart .view-empty .line-item-total {
  display: none;
}

.zone-branding .block-shopping-cart-block .content .view-shopping-cart .view-empty p {
  line-height: 3;
}

.zone-branding .block-shopping-cart-block .content .view-shopping-cart .line-item-quantity {
  margin-left: 50px;
}

.zone-branding .block-shopping-cart-block .content .view-shopping-cart .line-item-quantity span,
.zone-branding .block-shopping-cart-block .content .view-shopping-cart .view-empty p {
  color: #aaa;
}

.zone-branding .block-shopping-cart-block .content .view-shopping-cart .line-item-total span {
  color: #000;
  font-weight: 700;
}

/* admin toolbar */

body.toolbar #toolbar {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* mmenu */

#mmenu_right {
  .mm-header {
    background: url('/sites/default/files/webmeal_logo_alma.png') no-repeat center;
    background-size: contain;
  }

  i, .mmenu-expanded {
    display: none;
  }
  .mm-list .mmenu-mm-list-level-1 {
    margin-top: 20px;
  }
}

.mmenu-close {
  width: 100%;
  height: 100%;
  position: relative;

  span {
    position: absolute;
    right: 0;
    height: 1em;
    line-height: 1;
  }
}

/* page-checkout */

.page-checkout .profile ul.user-information li {
  border-bottom: 0;
  width: 31%;
}

/* footer */

.section-footer {
  .block-menu .menu a {
    color: #fff;
    font-weight: 600;
  }
}

/* region content bugfix */
#region-content {
  margin-left: 0;
  margin-right: 0;
}

#zone-content {
  & > * {
    padding-left: 10px;
    padding-right: 10px;
  }

  #messages {
    display: block;
    float: none;
    box-sizing: border-box;
  }
}

#slider-close {
  position: absolute;
  line-height: 1;
  text-align: left;
  right: 10px;
  top: 10px;
  font-weight: 800;
  font-size: medium;
  cursor: pointer;
}

.section-content {
  margin-top: 150px;
}

/* zipping */

@media (min-width: 980px) {

  .region-branding-inner {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  body:not(.front) {
    .zipped .region-branding {
      .branding-blocks {
        .block-menu {
          display: none;
        }
      }
    }
  }

  .zipped .region-branding {
    .logo-img {
      width: 20% !important;
    }

    .branding-blocks {
      width: 80% !important;

      .block-shipping-selector,
      .block-shopping-cart-block {
        position: relative;
        width: 40%;
        margin-left: 10%;
        top: initial;
        bottom: initial;
        right: initial;
        left: initial;
        display: inline-block;
        vertical-align: middle;
        height: 42px;
      }
    }
  }

  .not-logged-in .zipped .region-branding .branding-blocks .block-shopping-cart-block {
    margin-left: 60% !important;
  }

  .zipped .region-branding .branding-blocks .block-shipping-selector span a {
    font-size: 13px;
  }

  .zipped .region-branding-inner {
    height: 80px !important;
  }
}

.webmeal_hidden {
  display: none;
}

.food_expired {
  color: red;
  font-weight: bold;
  font-size: 1.1em;
}

.form-item-customer-profile-shipping-field-kedvezmeny-jogosultsag-und div.description,
.form-item-field-kedvezmeny-jogosultsag-und div.description {
  display: flex !important;
  border: 5px solid #2db45c;
  border-radius: 5px;
  padding: 1em;
  font-size: 1.1em;
}

.foodtypes-container h4 {
  text-transform: uppercase;
}

body {
  &:not(.toolbar-drawer) {
    &:not(.front) {
      .zone-branding-wrapper {
        top: 33px;
      }
    }
  }
}
