body.front {

  &:not(.toolbar-drawer) {
    .zone-branding-wrapper {
      top: -15px;
    }

    .section-content {
      margin-top: 127px;
    }
  }

  &.toolbar-drawer {
    #zone-branding {
      padding-top: 37px;
    }
  }

  .region-branding-inner {
    height: 150px;
  }

  .section-content {
    margin-top: 133px;
  }

  div#zone-content-wrapper {

    div.text {
      margin: 2em 10px 1em 10px;

      p {
        text-transform: uppercase;
        font-size: 28px;
        color: #888888;
        font-family: 'Open Sans', sans-serif;
        font-weight: 200;
        text-align: center;
      }
    }

    div.tiles {
      margin: 0 10px;
      text-align: center;

      ul {
        list-style: none;
        margin: 0;
      }

      li {
        display: inline-block;
        margin: 0 0 5% 0;
        max-width: 45%;

        &:first-of-type {
          margin-right: 5%;
        }
      }

      a {
        display: block;
        width: 100%;
      }

      img {
        box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 550px) {
  body.front {
    div#zone-content-wrapper {
      div.tiles {
        ul {
          li {
            max-width: initial;
            width: 100%;
            display: block;

            &:first-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
