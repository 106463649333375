/* page-user */

.page-user {
  .content {
    .profile {
      ul.user-information {
        li {
          width: 215px;
          border-bottom: 0;
          min-height: 220px;
          height: 220px;

          div.field-item {
            position: relative;

            a {
              bottom: initial;
              top: 0;
            }
          }
        }
      }
    }
  }
}